import { lazy } from "react";
import { paths } from "~/router/paths";
import { app } from "~/helpers/routes";

const BlogPage = lazy(() => import("~/pages/blog"));
const BlogGeneratePage = lazy(() => import("~/pages/blog/generate/generateNewBlog"));
const BlogGeneratedPage = lazy(() => import("~/pages/blog/generate/generatedBlog"));

export const routes: Array<any> = [
  {
    path: paths.blog.index,
    element: app(BlogPage),
  },
  {
    path: paths.blog.generate,
    element: app(BlogGeneratePage),
  },
  {
    path: paths.blog.generated,
    element: app(BlogGeneratedPage),
  },
];
