import { lazy } from "react";
import { app, fullscreen } from "~/helpers/routes";
import { paths } from "~/router/paths";

const HomePage = lazy(() => import("~/pages/home"));
const CancelationPage = lazy(() => import("~/pages/cancel"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(HomePage),
  },
  {
    path: paths.app.cancel,
    element: fullscreen(CancelationPage),
  }
];
