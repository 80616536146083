import BlogPost from "~/assets/svg/blogPostImg.svg";
import ProdDesc from "~/assets/svg/ProdDescImg.svg";
import FacebookAd from "~/assets/svg/facebookAdImg.svg";
import Insta from "~/assets/svg/instaImg.svg";
import Pinterest from "~/assets/svg/pinterestImg.svg";
import { IFeature } from "~/types/feature";
import { paths } from "~/router/paths";
import { TFunction } from "i18next";
import { Limits } from "~/types/billing";

export const features: IFeature[] = [
  { name: "features.blog", img: BlogPost, url: paths.blog.index },
  { name: "features.product", img: ProdDesc, url: paths.product.index },
  { name: "features.facebook", img: FacebookAd, url: paths.ads.index },
  { name: "features.instagram", img: Insta, url: paths.ads.index },
  { name: "features.pinterest", img: Pinterest, url: paths.ads.index }
];

const translationKeys = {
  paid_plan: {
    titleWithAbovePlans: "features.paid_plan.title_with_above",
    title: "features.paid_plan.title",
    description: "features.paid_plan.description",
    testimonial: "features.paid_plan.testimonial",
  },
  premium_keywords:  {
    titleWithAbovePlans: "features.premium_keywords.title_with_above",
    title: "features.premium_keywords.title",
    description: "features.premium_keywords.description",
    testimonial: "features.premium_keywords.testimonial",
  },
  ads_writer: {
    titleWithAbovePlans: "features.ads_writer.title_with_above",
    title: "features.ads_writer.title",
    description: "features.ads_writer.description",
    testimonial: "features.ads_writer.testimonial",
  },
  blog_generated: {
    titleWithAbovePlans: "features.blog_generated.title_with_above",
    title: "features.blog_generated.title",
    description: "features.blog_generated.description",
    testimonial: "features.blog_generated.testimonial",
  },
  chat: {
    titleWithAbovePlans: "features.chat.title_with_above",
    title: "features.chat.title",
    description: "features.chat.description",
    testimonial: "features.chat.testimonial",
  },
  descriptions_generated: {
    titleWithAbovePlans: "features.descriptions_generated.title_with_above",
    title: "features.descriptions_generated.title",
    description: "features.descriptions_generated.description",
    testimonial: "features.descriptions_generated.testimonial",
  },
  generation_count_limit: {
    titleWithAbovePlans: "features.generation_count_limit.title_with_above",
    title: "features.generation_count_limit.title",
    description: "features.generation_count_limit.description",
    testimonial: "features.generation_count_limit.testimonial",
  },
  save_descriptions: {
    titleWithAbovePlans: "features.save_descriptions.title_with_above",
    title: "features.save_descriptions.title",
    description: "features.save_descriptions.description",
    testimonial: "features.save_descriptions.testimonial",
  },
} satisfies Record<
  Limits,
  {
    title: string;
    titleWithAbovePlans: string;
    description: string;
    testimonial: string;
  }
>;

export const planEmojis: Record<string, string> = {
  starter: "⭐",
  pro: "✨",
  empire: "👑",
  unicorn: "🦄"
};

export const getFeaturesTranslations = (
  t: TFunction,
  limitType: Limits,
  planName: string,
  hasAbovePlans: boolean
) => {
  const translations = translationKeys[limitType];

  return {
    title: t(
      hasAbovePlans ? translations.titleWithAbovePlans : translations.title,
      { planName }
    ),
    description: t(translations.description, { planName }),
    testimonial: t(translations.testimonial, { planName }),
  };
};
