import styled from "styled-components";
import { getColor, getSize, responsive } from "~/helpers/style";

export const StepTitle = styled.h2`
  color: ${getColor("text")};
  font-size: ${getSize(1.6)};
  font-weight: 600;
  margin: 0;

  ${responsive("tablet")} {
    margin: ${getSize(1.3)} 0;
  };
`;

export const ButtonFixedContainer = styled.div`
  padding: ${getSize(1.3)};
  background-color: ${getColor("background")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    width: ${getSize(2)};
    height: ${getSize(2)};
  }

  & > svg > circle {
    stroke: ${getColor("white")};
  }
`;
