export interface IStoreLoginRequest {
  shop_token: string;
}

export interface IStoreConnect {
  shop_url: string;
}

export const STORE = ["STORE"];

export const SHOPIFY_PRODUCTS = ["SHOPIFY_PRODUCTS"];

export const SHOPIFY_COLLECTIONS = ["SHOPIFY_COLLECTIONS"];
