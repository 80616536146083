import { useEffect } from "react";

export const useUTM = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get("utm_campaign");
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmTerm = searchParams.get("utm_term");
    if (!utmCampaign && !utmSource && !utmMedium && !utmTerm) return;
    localStorage.setItem("utm_campaign", utmCampaign || "");
    localStorage.setItem("utm_source", utmSource || "");
    localStorage.setItem("utm_medium", utmMedium || "");
    localStorage.setItem("utm_term", utmTerm || "");
  }, []);

  const getUTM = (key: string) => {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return value;
  };

  return {
    campaign: getUTM("utm_campaign"),
    source: getUTM("utm_source"),
    medium: getUTM("utm_medium"),
    term: getUTM("utm_term"),
  };
};
