import {
  faCog,
  faHouseChimney,
  faFilePen,
  faDrawSquare,
  faFileLines,
  faSquareInfo,
  faPaintbrushPencil,
  faBell,
  faStore,
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";
import { DISABLE_PAYMENTS } from "~/helpers/plans";
import { useGetUnreadNotifications } from "~/api/notifications/queries";
import {
  triggerShowConnectStoreModal,
  triggerShowNotifications,
} from "~/helpers/customEvents";

export const navItems: Array<INavItem> = [
  {
    namePath: "nav.home",
    path: paths.app.home,
    icon: faHouseChimney,
  },
  {
    namePath: "product.product_editor",
    path: paths.product.index,
    icon: faFilePen,
  },
  {
    namePath: "ads.ads_writer",
    path: paths.ads.index,
    icon: faDrawSquare,
  },
  {
    namePath: "blog.blog_writer",
    path: paths.blog.index,
    icon: faFileLines,
  },
  {
    namePath: "nav.create_your_logo",
    path: "https://www.logome.ai/?utm_source=smartli",
    openInNewTab: true,
    icon: faPaintbrushPencil,
    newFlag: true,
  },
  {
    namePath: "settings.title",
    path: DISABLE_PAYMENTS ? paths.settings.account : paths.settings.plans,
    icon: faCog,
  },
  {
    namePath: "nav.notifications",
    path: "/notifications",
    icon: faBell,
    onClick: () => triggerShowNotifications(),
    getCountQuery: useGetUnreadNotifications,
  },
  {
    namePath: "nav.connect-store",
    path: "/connect-store",
    icon: faStore,
    onClick: () => triggerShowConnectStoreModal(),
  },
  {
    namePath: "nav.help_center",
    path: "https://help.smartli.ai/Smartli-3a54244151e346c0b295e3710c522208",
    openInNewTab: true,
    icon: faSquareInfo,
    // Uncomment this to show help center as a modal
    // showHelpCenterModal: true,
  },
];
