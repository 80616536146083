import React, { useContext, useState } from "react";

import { createContext } from "react";
import { IAd } from "~/types/ads";

interface IAdContextProps {
  ad: IAd | null;
  setAd: React.Dispatch<React.SetStateAction<IAd | null>>;
}

export const AdContext = createContext<IAdContextProps>({
  ad: null,
  setAd: () => {},
});

export const useAd = (): IAdContextProps => {
  const context = useContext(AdContext);
  if (!context) {
    throw new Error("A Ad must be setted");
  }
  return context;
};


interface IProps {
  children: React.ReactNode;
}

export const AdProvider: React.FC<IProps> = ({ children }) => {
  const [ad, setAd] = useState<IAd | null>(null);

  return (
    <AdContext.Provider value={{ ad, setAd }}>
      {children}
    </AdContext.Provider>
  );
};
