import * as S from "./styles";
import { StepProps } from "../steps";
import { MARKETPLACES } from "./constants";
import { useTranslation } from "react-i18next";
import RadioButton from "~/components/ui/Radio";
import { useEffect } from "react";

export const SelectMarketPlace = ({
  onboardingChoices,
  setOnboardingChoices,
}: StepProps) => {
  const { t } = useTranslation();

  const newMarketplaces = MARKETPLACES
    ? [...MARKETPLACES, { name: t("onboarding.noStore"), value: "no_store" }]
    : [];

  const handleNoStoreSelection = () => {
    setOnboardingChoices((old) => {
      return {
        generation_preferences: old?.generation_preferences || [],
        marketplaces: old?.marketplaces?.includes("no_store")
          ? []
          : ["no_store"],
      };
    });
  };

  useEffect(() => {
    if (
      onboardingChoices?.marketplaces?.length === 0 ||
      onboardingChoices?.marketplaces === undefined
    ) {
      handleNoStoreSelection();
    }
  }, [onboardingChoices]);

  const handleMarketplaceSelection = (value: string) => {
    setOnboardingChoices((old) => {
      return {
        generation_preferences: old?.generation_preferences || [],
        marketplaces: [value], // Only allow one marketplace to be selected at a time
      };
    });
  };

  const handleMarketplaceSelect = (catValue: string) => {
    if (catValue === "no_store") {
      handleNoStoreSelection();
    } else {
      handleMarketplaceSelection(catValue);
    }
  };

  return (
    <S.CardContainer>
      {newMarketplaces?.map((category) => (
        <S.CardPreference
          key={category.value}
          className={
            onboardingChoices?.marketplaces?.includes(category.value)
              ? "active"
              : ""
          }
          onClick={() => handleMarketplaceSelect(category.value)}>
          <RadioButton
            checked={onboardingChoices?.marketplaces?.includes(category.value)}
            onChange={() => handleMarketplaceSelect(category.value)}
          />
          {category.name}
        </S.CardPreference>
      ))}
    </S.CardContainer>
  );
};