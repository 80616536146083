import { IVideo } from "~/types/video";

export const videos: IVideo[] = [
  {
    url: "https://fast.wistia.net/embed/iframe/eqw5579q1g",
    titleKey: "video.install",
    time: "0:46",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/2disw8yh2d",
    titleKey: "video.create",
    time: "1:17",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/cwpdeenykx",
    titleKey: "video.generate",
    time: "0:52",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/yx65gl2i4j",
    titleKey: "video.import",
    time: "0:51",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/hy4zvjr15h",
    titleKey: "video.cancel",
    time: "0:38",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/1wkm1sdz96",
    titleKey: "video.create_ads",
    time: "1:00",
  },
  {
    url: "https://fast.wistia.net/embed/iframe/4hnba5f1ze",
    titleKey: "video.subscribe",
    time: "1:40",
  },
];
