import * as S from "./styles";
import { StepProps } from "../steps";
import { useTranslation } from "react-i18next";
import { GENERATION_PREFERENCES } from "./constants";
import RadioButton from "~/components/ui/Radio";
import CustomCheckbox from "~/components/ui/Checkbox";
import { useEffect } from "react";

export const SelectPreference = ({
  onboardingChoices,
  setOnboardingChoices,
}: StepProps) => {
  const { t } = useTranslation();

  const newPreferences = GENERATION_PREFERENCES
    ? [...GENERATION_PREFERENCES, { name: t("onboarding.all"), value: "all" }]
    : [];

  const areAllPreferencesSelected = () => {
    return (
      onboardingChoices?.generation_preferences?.length ===
        GENERATION_PREFERENCES.length &&
      GENERATION_PREFERENCES.every((category) =>
        onboardingChoices.generation_preferences.includes(category.value)
      )
    );
  };

  useEffect(() => {
    if (
      onboardingChoices?.generation_preferences?.length === 0 ||
      onboardingChoices?.generation_preferences === undefined
    ) {
      handleAllSelection();
    }
  }, [onboardingChoices]);

  const handleAllSelection = () => {
    if (areAllPreferencesSelected()) {
      setOnboardingChoices((old) => ({
        marketplaces: old?.marketplaces || [],
        generation_preferences: [],
      }));
    } else {
      setOnboardingChoices((old) => ({
        marketplaces: old?.marketplaces || [],
        generation_preferences: GENERATION_PREFERENCES.map(
          (category) => category.value
        ),
      }));
    }
  };

  const handlePreferenceSelection = (value: string) => {
    setOnboardingChoices((old) => {
      let prefs = [];
      if (old?.generation_preferences?.includes(value)) {
        prefs = old.generation_preferences.filter(
          (catValue) => catValue !== value
        );
      } else {
        prefs = [...(old?.generation_preferences || []), value];
      }

      return {
        marketplaces: old?.marketplaces || [],
        generation_preferences: prefs,
      };
    });
  };

  const handlePreferenceSelect = (catValue: string) => {
    if (catValue === "all") {
      handleAllSelection();
    } else {
      handlePreferenceSelection(catValue);
    }
  };

  const handleClassName = (category: string) => {
    if (onboardingChoices?.generation_preferences?.includes(category) && !areAllPreferencesSelected()) {
      return "active";
    }
    if (category === "all" && areAllPreferencesSelected()) return "active";
    if (areAllPreferencesSelected() && category !== "all") return "active disabled";

    return "";
  }

  return (
    <S.CardContainer>
      {newPreferences?.map((category) => (
        <S.CardPreference
          key={category.value}
          className={handleClassName(category.value)}
          onClick={() => handlePreferenceSelect(category.value)}>
          <>
            {category.value === "all" ? (
              <RadioButton
                checked={areAllPreferencesSelected()}
                onChange={() => handlePreferenceSelect(category.value)}
              />
            ) : (
              <CustomCheckbox
                onChange={() => handlePreferenceSelect(category.value)}
                checked={onboardingChoices?.generation_preferences?.includes(
                  category.value
                )}
              />
            )}
          </>
          {category.name}
        </S.CardPreference>
      ))}
    </S.CardContainer>
  );
};
