import styled from "styled-components";
import { getColor, getProperty, getSize } from "~/helpers/style";
import { UIFlexProps } from "~/types/style";

interface StyledSeparatorProps extends UIFlexProps {
  margin?: number;
}

export const VerticalSeparator = styled.div<StyledSeparatorProps>`
  display: flex;
  align-self: stretch;
  flex-grow: 1;
  width: 1px !important;
  max-width: 1px;
  margin: 0 ${(props) => getSize(getProperty("margin", 0)(props))};
  background-color: ${getColor("borderSecondary")};
`;

export const HorizontalSeparator = styled.div<StyledSeparatorProps>`
  display: block;
  width: 100%;
  height: 1px;
  margin: ${(props) => getSize(getProperty("margin", 0)(props))} 0;
  background-color: ${getColor("borderSecondary")};
`;
