import { IRootPaths } from "~/types/routing";

import { paths as auth } from "~/router/paths/auth";
import { paths as app } from "~/router/paths/app";
import { paths as settings } from "~/router/paths/settings";
import { paths as checkout } from "~/router/paths/checkout";
import { paths as ads } from "~/router/paths/ads";
import { paths as product } from "~/router/paths/product";
import { paths as blog } from "~/router/paths/blog";

export const paths: IRootPaths = {
  auth,
  app,
  checkout,
  settings,
  ads,
  product,
  blog,
};
