export const GENERATION_PREFERENCES = [
  { name: "Product Descriptions", value: "product_descriptions" },
  { name: "Blog Posts", value: "blog_posts" },
  { name: "Ads Writer", value: "ads" },
];

export const MARKETPLACES = [
  { name: "Shopify", value: "shopify" },
  { name: "Wix", value: "wix" },
  { name: "Amazon", value: "amazon" },
];