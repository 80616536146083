import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SVGIcon } from "./types";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IThemeColors } from "~/types/theme";
import * as S from "./styles";
interface IProps {
  icon: SVGIcon;
  svgProp?: React.SVGProps<SVGSVGElement>;
  size?: SizeProp;
  color?: keyof IThemeColors | string;
}

export const SVG = (props: IProps) => {
  const { icon, svgProp, size, ...rest } = props;
  const width = svgProp?.width || undefined;

  return (
    <S.FontAwesomeIconWrapper color={props.color}>
      {/* @ts-ignore */}
      <FontAwesomeIcon size={size} icon={icon} width={width} {...rest} />
    </S.FontAwesomeIconWrapper>
  );
};
