import { lazy } from "react";

import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const AdsWriterPage = lazy(() => import("~/pages/ads-writer"));
const GeneratePage = lazy(() => import("~/pages/ads-writer/generate"));

export const routes: Array<any> = [
  {
    path: paths.ads.index,
    element: app(AdsWriterPage),
  },
  {
    path: paths.ads.generate,
    element: app(GeneratePage),
  }
];
