import { Dispatch, SetStateAction } from "react";
import { IOnboardingChoices } from "~/types/account";
import { SelectPreference } from "./components/select-preference";
import { SelectMarketPlace } from "./components/select-marketplace";

export interface StepProps {
  onboardingChoices: IOnboardingChoices | undefined;
  setOnboardingChoices: Dispatch<
    SetStateAction<IOnboardingChoices | undefined>
  >;
}

interface Step {
  titleKey: string;
  component: (props: StepProps) => JSX.Element;
}

export const steps: Step[] = [
  {
    titleKey: "onboarding.category_title",
    component: (props: StepProps) => {
      return <SelectPreference {...props} />;
    },
  },
  {
    titleKey: "onboarding.marketplace_title",
    component: (props: StepProps) => {
      return <SelectMarketPlace {...props} />;
    },
  },
];
