import { lazy } from "react";

import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const ProductEditorPage = lazy(() => import("~/pages/product-editor"));
const GenerateProductPage = lazy(() => import("~/pages/product-editor/generate"));

export const routes: Array<any> = [
  {
    path: paths.product.index,
    element: app(ProductEditorPage),
  },
  {
    path: paths.product.generate,
    element: app(GenerateProductPage)
  },
];
