export enum SubscriptionType {
  ANNUAL = "yearly",
  MONTHLY = "monthly",
}

export const subscriptionTypeToBoolean: Record<SubscriptionType, boolean> = {
  [SubscriptionType.ANNUAL]: true,
  [SubscriptionType.MONTHLY]: false,
};

export interface ILimit {
  ads_writer: boolean;
  blog_generated: number;
  chat: boolean;
  descriptions_generated: number;
  generation_count_limit: number;
  premium_keywords: boolean;
  save_descriptions: boolean;
}

export enum Limits {
  PAID_PLAN = "paid_plan",
  ADS_WRITER = "ads_writer",
  BLOG_GENERATED = "blog_generated",
  CHAT = "chat",
  DESCRIPTIONS_GENERATED = "descriptions_generated",
  GENERATION_COUNT_LIMIT = "generation_count_limit",
  PREMIUM_KEYWORDS = "premium_keywords",
  SAVE_DESCRIPTIONS = "save_descriptions",
}

export enum InvoiceStatus {
  PAID = "paid",
  UNPAID = "unpaid",
  DRAFT = "draft",
  PENDING = "pending",
  SUCCESS = "succeeded",
  FAILED = "failed",
}

export interface SubscriptionHistory {
  period_start: string;
  period_end: string;
}

export interface Invoice {
  id: number;
  invoice_pdf?: string;
  payment_external_id: string;
  payment_method?: string;
  amount: number;
  status: string;
  subscription_history: SubscriptionHistory;
  created_at: string;
  entity_type: "subscription" | "one_time";
}

export interface IPlan {
  id?: string;
  interval?: string;
  status?: string;
  cost_per_month: number;
  old_cost_per_month?: number;
  created_at?: string;
  updated_at?: string;
  features: string[];
  limits?: ILimit;
  trial_days?: number;
  name: string;
  is_highlighted: boolean;
  months_off: number;
  stripe_upgrade_funnel_coupon_code: string | null;
  stripe_upgrade_funnel_coupon: {
    duration_in_months: number;
    percent_off: number;
  } | null;
}

export interface IPaymentValues {
  name?: string;
  payment_method_id?: string;
  setup_intent?: string;
  promo_code_id?: string;
}

export interface IProration {
  old_plan_proration: number;
  new_plan_proration: number;
  total: number;
}
